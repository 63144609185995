import React, { useState } from 'react';
function NavBar() {

  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => { setIsOpen(!isOpen);};
  return (
    <>
      <nav id="desktop-nav">
        <div className="logo">Nadim Anwar</div>
        <div>
          <ul className="nav-links">
            <li><a href="#about">About</a></li>
            <li><a href="#experience">Experience</a></li>
            <li><a href="#projects">Projects</a></li>
            <li><a href="#contact">Contact</a></li>
            <li><a href="https://www.npmjs.com/~anwarnadim7">NPM Profile</a></li>
          </ul>
        </div>
      </nav>
      <nav id="hamburger-nav">
        <div className="logo">Nadim Anwar</div>
        <div className="hamburger-menu">
          <div className={`hamburger-icon ${isOpen ? 'open' : ''}`} onClick={toggleMenu}>
            {/* Add your icon content here */}
            <span></span>
            <span></span>
            <span></span>
          </div>
          <div className={`menu-links ${isOpen ? 'open' : ''}`}>
            {/* Add your menu links here */}
            <li><a href="#about" onclick="toggleMenu()">About</a></li>
            <li><a href="#experience" onclick="toggleMenu()">Experience</a></li>
            <li><a href="#projects" onclick="toggleMenu()">Projects</a></li>
            <li><a href="#contact" onclick="toggleMenu()">Contact</a></li>
            <li><a href="https://www.npmjs.com/~anwarnadim7">NPM Profile</a></li>
          </div>
        </div>
      </nav>
    </>
  )
}

export default NavBar