import React from "react";
import pic from "../assets/image.jpg";
import exp from "../assets/experience.png";
import edu from "../assets/education.png";
import arrow from "../assets/arrow.png";
function About() {
  return (
    <>
      <section id="about">
        <p className="section__text__p1">Get To Know More</p>
        <h1 className="title">About Me</h1>
        <div className="section-container">
          <div className="section__pic-container">
            <img src={pic} alt="Profile picture" className="about-pic" />
          </div>
          <div className="about-details-container">
            <div className="about-containers">
              <div className="details-container">
                <img src={exp} alt="Experience icon" className="icon" />
                <h3>Experience</h3>
                <p>
                  3+ years <br />
                  Frontend Development
                </p>
              </div>
              <div className="details-container">
                <img src={edu} alt="Education icon" className="icon" />
                <h3>Education</h3>
                <p>
                  BCA Bachelors Degree
                  <br />
                  M.S Masters Degree
                </p>
              </div>
            </div>
            <div className="text-container">
              <p>
                Front End Developer working with JavaScript, HTML/CSS, ReactJs
                to deliver exceptional customer experiences. Tech enthusiast,
                multitasker and adept at contributing to a highly collaborative
                work environment, finding solutions, and determining customer
                satisfaction.
              </p>
            </div>
          </div>
        </div>
        <img
          src={arrow}
          alt="Arrow icon"
          className="icon arrow"
          onclick="location.href='./#experience'"
        />
      </section>
    </>
  );
}

export default About;
