import React from 'react'
import arrow from '../assets/arrow.png';
import project1 from '../assets/project-1.png';
import project2 from '../assets/project-2.png';
import project3 from '../assets/project-3.png';
import npm from '../assets/npm.png';
function Projects() {
  return (
    <>
     <section id="projects">
      <p className="section__text__p1">Browse My Recent</p>
      <h1 className="title">Projects</h1>
      <div className="experience-details-container">
        <div className="about-containers">
          <div className="details-container color-container">
            <div className="article-container">
              <img
                src={project1}
                alt="Project 1"
                className="project-img"
              />
            </div>
            <h2 className="experience-sub-title project-title">Dictionary App (Dictionary Api)</h2>
            <div className="btn-container">
              <a
                className="btn btn-color-2 project-btn"
                href="https://github.com/anwarnadim7/dictionary-app"
              >
                Github
              </a>
              <a
                className="btn btn-color-2 project-btn"
                href="https://dictionary-audio.netlify.app/"
              >
                Live Demo
              </a>
            </div>
          </div>
          <div className="details-container color-container">
            <div className="article-container">
              <img
                src={project2}
                alt="Project 2"
                className="project-img"
              />
            </div>
            <h2 className="experience-sub-title project-title">GitHub User Search (GitHub Api)</h2>
            <div className="btn-container">
              <a
                className="btn btn-color-2 project-btn"
                href='https://github.com/anwarnadim7/github-user-search-app'
              >
                Github
              </a>
              <a
                className="btn btn-color-2 project-btn"
                href='https://find-git-user.netlify.app/'
              >
                Live Demo
              </a>
            </div>
          </div>
          <div className="details-container color-container">
            <div className="article-container">
              <img
                src={project3}
                alt="Project 3"
                className="project-img"
              />
            </div>
            <h2 className="experience-sub-title project-title">ToDo Application (Firebase)</h2>
            <div className="btn-container">
              <a
                className="btn btn-color-2 project-btn"
                href='https://github.com/anwarnadim7/todo'
              >
                Github
              </a>
              <a
                className="btn btn-color-2 project-btn"
                href='https://ndm-todopp.netlify.app/'
              >
                Live Demo
              </a>
            </div>
          </div>
        </div>
      </div>
      <br/>
      
     
    </section>
    </>
  )
}

export default Projects